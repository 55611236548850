import React from 'react';
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image';
import OmniLink from './OmniLink';
import logoDisano from '@/public/logo-disano.png';
import logoFosnova from '@/public/logo-fosnova.png';

interface LogoProps {
  disanoOrFosnova?: boolean,
}

const Logo: React.FC<LogoProps> = ({ disanoOrFosnova = true }) => {
  const { t } = useTranslation('common');
	return (
    <OmniLink href="/" sx={{ my: 'auto', fontSize: 0, lineHeight: 0, alignSelf: 'center' }}>
      {disanoOrFosnova ? (
        <Image priority src={logoDisano} alt={t('logo-disano-alt',{},{default:'Disano'})} width="165" height="51" />
      ) : (
        <Image priority src={logoFosnova} alt={t('logo-fosnova-alt',{},{default:'Fosnova'})} width="165" height="38" />
      )}
    </OmniLink>
	);
};

export default Logo;
